<template>
  <div>
    <!-- modal loading -->
    <b-modal
      id="modal-loading"
      centered
      title="Loading..."
      size="sm"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="text-center p-4">
        <h1>Loading...</h1>
      </div>
    </b-modal>

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'onboarding' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'browse-conversions' }">
              Conversions
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              {{ $attrs.pageTitle || "Browse Conversions" }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="p-2">
          <!-- <feather-icon
            icon="FolderIcon"
            size="24"
          /> -->
          {{ $attrs.pageTitle || "All Conversions" }}
        </h2>
      </b-col>

      <b-col
        md="6"
        class="mb-2"
      >
        <b-row>
          <b-col class="text-right">
            <b-button
              class="ml-1"
              size="sm"
              variant="outline-primary"
              @click="refreshConversions"
            >
              <font-awesome-icon :icon="['fas', 'rotate-right']" />
              Refresh Conversions
            </b-button>
            <b-button
              class="ml-1"
              size="sm"
              variant="outline-secondary"
              @click="handleIgnoredForms"
            >
              <font-awesome-icon :icon="['fas', 'pencil-slash']" />
              Ignored Forms
            </b-button>
            <b-button
              v-if="pageSpamConversions"
              :disabled="visibleConversions.length < 1"
              class="ml-1"
              size="sm"
              variant="warning"
              @click="handleDownloadSpam"
            >
              <font-awesome-icon :icon="['fas', 'download']" />
              Download CSV
            </b-button>
            <b-button
              v-if="pageNewConversions"
              class="ml-1"
              size="sm"
              variant="outline-secondary"
              @click="openTour"
            >
              <font-awesome-icon
                :icon="['fas', 'question']"
                aria-label="Show Tutorial"
              />
              <!-- Help -->
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col class="mb-1 text-right">
            <b-button
              variant="secondary"
              class="ml-1"
              :style="{ float: 'right' }"
              @click="handleResetDate"
            >
              Reset
            </b-button>
            <flat-pickr
              id="select-date-range"
              v-model="dateRange"
              class="form-control"
              :style="{ maxWidth: '230px', float: 'right' }"
              :config="{ mode: 'range' }"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-overlay
      variant="transparent"
      :show="loadingOverlay"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <b-spinner
            type="grow"
            variant="dark"
          />
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Please wait...</span>
        </div>
      </template>

      <section v-if="visibleConversions">
        <!-- table row -->
        <b-card>
          <b-row v-if="!userOnboarded">
            <b-col>
              <b-alert
                variant="danger"
                show
              >
                <div class="alert-body">
                  You must finish onboarding before viewing conversions.
                </div>
              </b-alert>

              <b-button
                variant="danger"
                :to="{ name: 'onboarding' }"
              >
                Return to Setup
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="userOnboarded && visibleConversions.length <= 0">
            <b-col>
              <div class="text-center mt-4 mb-1">
                You don't have any
                <strong>{{
                  $attrs.filterParams && $attrs.filterParams.status
                    ? $attrs.filterParams.status
                    : ""
                }}</strong>
                conversions<span v-if="startDate && endDate">
                  from <strong>{{ startDate }}</strong> to
                  <strong>{{ endDate }}</strong></span>.
              </div>
              <div class="text-center mb-4">
                <b-button
                  variant="primary"
                  :to="{ name: 'browse-conversions-verified' }"
                >
                  View Verified
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="userOnboarded && visibleConversions.length > 0">
            <b-col
              md="2"
              sm="4"
            >
              <b-form-group
                class="mb-0"
                label="Per page"
                label-for="perPageSelect"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                  @change="handlePerPage"
                />
              </b-form-group>
            </b-col>
            <!-- STATUS AND CAMPAIGN FILTER
            <b-col
              md="6"
              class="my-1"
            >
              <b-form inline>
                <b-form-select
                  id="statusSelect"
                  v-model="selectedStatus"
                  size="sm"
                  :options="statusOptions"
                  @change=""
                />
                <b-form-select
                  id="statusSelect"
                  v-model="selectedCampaign"
                  size="sm"
                  :options="campaignOptions"
                  @change=""
                />
              </b-form>
            </b-col> -->

            <!-- <b-col
              md="6"
              class="my-1"
            >
              <b-form-group class="mb-0">
                <label
                  class="d-block text-sm-left"
                  for="filterInput"
                >Filter</label>
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col> -->

            <!--
                :per-page="perPage"
                :current-page="currentPage"
            -->
            <b-col cols="12 mt-2">
              <b-table
                hover
                responsive
                :items="visibleConversions"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:head()="data">
                  <span
                    v-b-tooltip.hover
                    :title="data.field.tooltip"
                  >
                    {{ data.label }}
                  </span>
                </template>

                <!-- <template #cell(customer_descriptive_name)="data">
                  <b-link
                    :to="showTutorial ? '#' : { name: 'view-conversion', params: { id: data.item.id }, query: { returnTo: windowPathname } }"
                    @click="showTutorial ? '' : handleItemView(data.item.id)"
                  >
                    {{ data.item.customer_descriptive_name }}
                  </b-link>
                </template> -->

                <template #cell(date)="data">
                  <div
                    v-if="data.item.date.ago"
                    v-b-popover.hover.top="
                      data.item.date.date + ' ' + data.item.date.time
                    "
                  >
                    <span class="d-block font-weight-bold hover">
                      {{ data.item.date.date }}
                    </span>
                    <span class="text-secondary hover">{{ data.item.date.ago }}</span>
                  </div>
                  <div v-else>
                    <span class="d-block font-weight-bold">
                      {{ data.item.date.date }}
                    </span>
                    <span class="text-secondary">{{ data.item.date.time }}</span>
                  </div>
                </template>

                <template #cell(location)="data">
                  <div
                    v-if="data.item.form"
                    v-b-popover.hover.top="data.item.location.source"
                  >
                    <span class="d-block font-weight-bold hover">
                      {{ limitString(data.item.location.name, 40) }}
                    </span>
                    <span class="text-secondary hover">{{
                      limitString(data.item.location.source, 30)
                    }}</span>
                  </div>
                  <div v-else>
                    <span class="d-block font-weight-bold">
                      {{ limitString(data.item.location.name, 40) }}
                    </span>
                    <span class="text-secondary">{{
                      limitString(data.item.location.source, 30)
                    }}</span>
                  </div>
                </template>

                <template #cell(message)="data">
                  <div
                    v-if="data.item.form"
                    v-b-popover.hover.top="
                      data.item.form && data.item.form.comments
                        ? data.item.form.comments
                        : ''
                    "
                  >
                    <span class="d-block font-weight-bold hover">
                      {{ limitString(data.item.form.comments, 90) }}
                    </span>
                  </div>
                  <div v-else>
                    <span class="d-block font-weight-bold">
                      {{ limitString("No Message", 40) }}
                    </span>
                  </div>
                  <!--<font-awesome-icon
                    v-b-popover.hover.top="data.item.form && data.item.form.comments ? data.item.form.comments : ''"
                    class="border rounded p-1"
                    :icon="message['M']"
                  />-->
                </template>

                <template #cell(source)="data">
                  <font-awesome-icon
                    v-b-popover.hover.top="
                      `${sourceTooltip[data.item.source]} - ${
                        data.item.utm.utm_source
                          ? data.item.utm.utm_source
                          : data.item.domain
                      }`
                    "
                    class="border rounded p-1"
                    :icon="sources[data.item.source] || data.item.source"
                  />
                </template>

                <template #cell(type)="data">
                  <font-awesome-icon
                    v-b-popover.hover.top="typeTooltip[data.item.type]"
                    class="border rounded p-1 hover"
                    :icon="types[data.item.type] || data.item.type"
                  />
                </template>

                <template #cell(contact)="data">
                  <b-link
                    v-if="data.item.form"
                    :to="
                      showTutorial
                        ? '#'
                        : { name: 'view-conversion', params: { id: data.item.id }, query: { returnTo: windowPathname } }
                    "
                    @click="showTutorial ? '' : handleItemView(data.item.id)"
                  >
                    <span
                      v-b-popover.hover.top="
                        data.item.form.comment ? data.item.form.comment : ''
                      "
                      class="d-block font-weight-bold"
                    >
                      {{
                        data.item.form.full_name
                          ? data.item.form.full_name
                          : data.item.form.first_name || data.item.form.last_name
                            ? `${data.item.form.first_name} ${data.item.form.last_name}`
                            : ""
                      }}
                    </span>
                    <span
                      v-if="data.item.type == 'F'"
                      class="text-secondary"
                    >{{
                      data.item.form.email
                    }}</span>
                    <span
                      v-if="data.item.type == 'P'"
                      class="text-secondary"
                    >{{
                      data.item.form.phone_number
                    }}</span>
                  </b-link>
                </template>

                <template #cell(status)="data">
                  <b-badge
                    class="text-capitalize"
                    :class="`status_${data.item.status}`"
                    :variant="statusColor[data.item.status] || 'outline-secondary'"
                  >
                    {{ data.item.status }}
                  </b-badge>
                </template>

                <template #cell(rating)="data">
                  <b-form-rating
                    v-b-popover.hover.top="
                      data.item.conversion_currency
                        ? `${data.item.conversion_value_pennies * 0.01} ${
                          data.item.conversion_currency
                        }`
                        : ''
                    "
                    :value="
                      calculateStarRating(
                        data.item.conversion_value_pennies,
                        data.item.status
                      )
                    "
                    :class="{ 'text-success': data.item.status === 'won' }"
                    size="sm"
                    inline
                    no-border
                    readonly
                  />
                </template>

                <template #cell(actions)="data">
                  <div class="actions-bar">
                    <div v-if="data.item.status === 'won'">
                      <!-- conversion is 'won' -->
                      <span class="d-inline-block action-button btn-viewdetails">
                        <b-badge
                          v-b-popover.hover.top="'View Details'"
                          class="text-capitalize"
                          variant="light-primary"
                          :to="
                            showTutorial
                              ? '#'
                              : { name: 'view-conversion', params: { id: data.item.id }, query: { returnTo: windowPathname } }
                          "
                        >
                          <font-awesome-icon :icon="['far', 'eye']" />
                        </b-badge>
                      </span>
                    </div>
                    <div v-else-if="!data.item.has_form_remap && data.item.type === 'F'">
                      <!-- TODO: show form settings only for forms -->
                      <!-- conversion hasn't been mapped yet -->
                      <span class="d-inline-block action-button btn-formsettings">
                        <b-button
                          variant="success"
                          @click="showTutorial ? '' : handleItemRemap(data.item.id)"
                        >
                          <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                          Form Settings
                        </b-button>
                      </span>
                    </div>
                    <div v-else-if="!data.item.can_verify">
                      <!-- conversion is on verification cooldown -->
                      <span class="d-inline-block action-button btn-formsettings">
                        <b-button
                          variant="outline-warning"
                          @click="handleItemCooldown(data.item)"
                        >
                          <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                          Not Ready
                        </b-button>
                      </span>
                    </div>
                    <div v-else>
                      <!-- conversion is ready -->
                      <span class="d-inline-block action-button btn-verified">
                        <b-badge
                          v-b-popover.hover.top="'Approve / Verify'"
                          class="text-capitalize"
                          variant="light-success"
                          @click="showTutorial ? '' : handleItemApprove(data.item.id)"
                        >
                          <font-awesome-icon :icon="['fas', 'star']" />
                        </b-badge>
                      </span>
                      <span class="d-inline-block action-button btn-rejected">
                        <b-badge
                          v-b-popover.hover.top="'Decline / Reject'"
                          class="text-capitalize"
                          variant="light-danger"
                          @click="showTutorial ? '' : handleItemReject(data.item.id)"
                        >
                          <font-awesome-icon :icon="['fas', 'hand']" />
                        </b-badge>
                      </span>
                      <span class="d-inline-block action-button btn-spam">
                        <b-badge
                          v-b-popover.hover.top="'Flag as Spam'"
                          class="text-capitalize"
                          variant="light-warning"
                          @click="showTutorial ? '' : handleItemSpam(data.item.id)"
                        >
                          <font-awesome-icon :icon="['fas', 'ban']" />
                        </b-badge>
                      </span>
                      <span class="d-inline-block action-button btn-viewdetails">
                        <b-badge
                          v-b-popover.hover.top="'View Details'"
                          class="text-capitalize"
                          variant="light-primary"
                          :to="
                            showTutorial
                              ? '#'
                              : { name: 'view-conversion', params: { id: data.item.id }, query: { returnTo: windowPathname } }
                          "
                        >
                          <font-awesome-icon :icon="['far', 'eye']" />
                        </b-badge>
                      </span>

                      <!-- Dropdown -->
                      <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          @click="showTutorial ? '' : handleItemApprove(data.item.id)"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'star']"
                            class="text-success"
                          />
                          <span class="align-middle ml-50">Approve / Verify</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="showTutorial ? '' : handleItemReject(data.item.id)"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'hand']"
                            class="text-danger"
                          />
                          <span class="align-middle ml-50">Decline / Reject</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="showTutorial ? '' : handleItemSpam(data.item.id)"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'ban']"
                            class="text-warning"
                          />
                          <span class="align-middle ml-50">Flag as Spam</span>
                        </b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item
                          :to="
                            showTutorial
                              ? '#'
                              : { name: 'view-conversion', params: { id: data.item.id }, query: { returnTo: windowPathname } }
                          "
                          @click="showTutorial ? '' : handleItemView(data.item.id)"
                        >
                          <font-awesome-icon
                            :icon="['far', 'eye']"
                            class="text-primary"
                          />
                          <span class="align-middle ml-50">View Details</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-col>

            <b-col md="6">
              <span
                class="text-secondary"
              >Showing 1 to {{ visibleConversions.length }} of
                {{ totalTableRows }} entries</span>
            </b-col>
            <b-col md="6">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalTableRows"
                :per-page="perPage"
                align="right"
                class="my-0"
                @page-click="handlePaginationClick"
              />
            </b-col>
          </b-row>
        </b-card>
      </section>
      <section v-if="errorMessage || !visibleConversions">
        <b-card>
          <b-row>
            <b-col>
              <b-alert
                v-if="errorMessage"
                variant="danger"
                show
              >
                <div class="alert-body">
                  {{ errorMessage.error || errorMessage.message }}
                </div>
              </b-alert>

              <b-button
                variant="danger"
                :to="{ name: 'onboarding' }"
              >
                Return to Setup
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </section>
    </b-overlay>

    <ApproveConversionModal
      ref="conversionModal"
      :selected-conversion="selectedConversion"
      :selected-modal-action="selectedModalAction"
      :return-to="windowPathname"
      @refresh-event="refreshConversions"
    />

    <b-modal
      id="modal-all-conversions-intro"
      centered
      title="Welcome to Converifai"
      size="lg"
      ok-title="Next"
      ok-only
      @ok="newConversionsPopUp"
    >
      <p>
        As the Converifai website tag measures form submissions and phone calls (coming
        soon), this <b>'All Conversions'</b> page will contain all conversion types
        regardless of where they are in the process.
      </p>
      <p>
        <img
          src="https://converifai.com/wp-content/uploads/2023/06/features4.png"
          class="img-fluid"
          alt="all conversions"
        >
      </p>
    </b-modal>
    <b-modal
      id="modal-new-conversions-intro"
      centered
      title="New Conversions"
      size="lg"
      ok-title="Next"
      ok-only
      @ok="openTour"
    >
      <p>
        The <b>'New Conversions'</b> page displays the conversions which have not yet been
        reviewed or actioned.
      </p>
      <p>
        Not all conversions are leads! Form spam, unqualified leads, and test form
        submissions are a few examples of conversions that do not bring value.
      </p>
      <p>
        Low-value and no-value conversions (i.e. "garbage leads") waste your sales team's
        time, make your marketing performance reporting less accurate, and can even cause
        your marketing and advertising to optimize focused on getting more junk.
      </p>
      <p>
        By Verifying your conversions, your use of Converifai ensures that only high
        quality leads are sent to your sales team, your reporting is more accurate, and
        there is strong alignment across your organization.
      </p>
    </b-modal>
    <app-tour
      name="newConversions"
      :steps="newConversionTour"
      :callbacks="newConversionTourCallbacks"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BBadge,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BFormGroup,
  BFormRating,
  BFormSelect, // BForm, BFormInput,
  BLink,
  BOverlay,
  BPagination,
  BTable,
  BSpinner,
  VBPopover,
  VBTooltip,
  BModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
import ApproveConversionModal from '../components/conversion-modal/ConversionModal.vue'

/*
 * Create placeholder data for tutorial
 */
const tutorialDataArray = []
for (let i = 0; i < 5; i++) {
  const placeholderData = {
    date: {
      ago: '1 hour ago',
      date: new Date().toDateString(),
      time: '00:00',
    },
    domain: 'testdomain.site',
    form: {
      first_name: 'Customer',
      last_name: 'Name',
      email: 'customer@email.com',
    },
    has_form_remap: true,
    id: 0,
    is_duplicate: false,
    location: {
      name: 'tutorial-form',
      source: '/conversions',
    },
    organization: {},
    source: 'D',
    status: 'new',
    status_id: 1,
    type: 'F',
    can_verify: true,
    utm: {
      utm_source: 'google',
    },
  }
  placeholderData.id += 1
  if (i === 0) {
    placeholderData.has_form_remap = false
    placeholderData.form = {}
  }
  tutorialDataArray.push(placeholderData)
}

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormGroup,
    BFormRating,
    BFormSelect,
    BLink,
    BOverlay,
    BPagination,
    BTable,
    BSpinner,
    ApproveConversionModal,
    AppTour,

    // date picker
    flatPickr,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user
      loadingOverlay: true,
      errorMessage: null,
      windowPathname: window.location.pathname + window.location.search,

      dateRange: null,
      startDate: null,
      endDate: null,
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      perPage: this.$route.query.size ? this.$route.query.size : 10,
      pageOptions: [5, 10, 15, 30, 45, 75],
      selectedStatus: null,
      statusOptions: [
        { text: 'Status', value: null },
        { text: 'All', value: 'all' },
        { text: 'New', value: 'new' },
        { text: 'Verified', value: 'verified' },
        { text: 'Rejected', value: 'rejected' },
        { text: 'Pending', value: 'pending' },
        { text: 'Lost', value: 'lost' },
        { text: 'Won', value: 'won' },
      ],
      selectedCampaign: null,
      campaignOptions: [
        { text: 'Campaign', value: null },
        { text: 'All', value: 'all' },
      ],
      // totalRows: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      fields: [
        // { key: 'id', label: 'Id', sortable: true },
        // { key: 'campaign_name', label: 'Campaign', sortable: true },
        {
          key: 'date',
          label: 'Date',
          tooltip: 'When the conversion occurred',
        },
        {
          key: 'location',
          label: 'Location',
          tooltip: 'Form ID and page path of the visitor when the conversion was sent',
        },
        {
          key: 'message',
          label: 'Message',
          tooltip: 'The comment or message the conversion contained',
        },
        {
          key: 'source',
          label: 'Source',
          tooltip: 'The origin of the visitor who completed a conversion action',
        },
        {
          key: 'type',
          label: 'Type',
          tooltip:
            'Indicates where the lead came from before converting at the website (i.e. utm_source)',
        },
        {
          key: 'contact',
          label: 'Contact',
          tooltip: 'Contact information from the conversion',
        },
        {
          key: 'status',
          label: 'Status',
          tooltip: 'The status of the conversion',
        },
        {
          key: 'rating',
          label: 'Value',
          tooltip: 'The estimated conversion value',
        },
        {
          key: 'actions',
          label: 'Actions',
          tooltip: 'Conversion actions that can be taken',
        },
      ],
      statusColor: {
        verified: 'light-success',
        flagged: 'light-warning',
        spam: 'light-warning',
        new: 'light-info',
        rejected: 'light-danger',
        pending: 'light-warning',
        lost: 'light-danger',
        won: 'light-success',
      },
      types: {
        F: 'fa-solid fa-table-list',
        P: 'fa-solid fa-phone text-dark-green',
        O: 'fa-solid fa-border-none',
      },
      typeTooltip: {
        F: 'Form',
        P: 'Phone',
        O: 'Other',
      },
      sources: {
        D: 'fa-solid fa-globe',
        G: 'fa-brands fa-google',
        U: 'fa-solid fa-question',
      },
      message: {
        M: 'fa-solid fa-comment',
      },
      sourceTooltip: {
        D: 'Direct',
        G: 'Google',
        U: 'Unknown',
      },
      defaultFilterParams: this.$attrs.filterParams,

      selectedConversion: 0,
      selectedModalAction: 'verify',
      chartData: [],
      newConversionTour: [
        {
          target: '.status_new',
          header: {
            title: 'New Conversions',
          },
          content:
            'Conversions which have not been Verified or Rejected and should be reviewed.',
        },
        {
          target: '.actions-bar .btn-formsettings',
          header: {
            title: 'Action: Form Needs Setup',
          },
          content:
            'Conversions received from a new form that has not yet been configured.',
        },
        {
          target: '.actions-bar .btn-verified',
          header: {
            title: 'Action: Verify a Conversion',
          },
          content:
            'Conversions which appear to be a good opportunity, should be used for marketing optimization, and should be followed-up by the sales team, can be marked as "Verified."',
        },
        {
          target: '.actions-bar .btn-rejected',
          header: {
            title: 'Action: Reject a Conversion',
          },
          content:
            'Conversions which should not be sent to sales, used for marketing optimization, or appear in performance reporting. Existing customer requests, junk or unqualified leads, etc.',
        },
        {
          target: '.actions-bar .btn-spam',
          header: {
            title: 'Action: Flag a Conversion as Spam',
          },
          content:
            'Conversions you mark as spam, unsolicited commercial messages sent to a large number of recipients. See: junk/garbage that should be reported as spam to advertising networks such as Google and Facebook.',
        },
        {
          target: '.actions-bar .btn-viewdetails',
          header: {
            title: 'Action: View Conversion Details',
          },
          content:
            "This shortcut will bring you to the full details of the conversion in question, where you'll be able to see all of the form contents, adjust the form settings, and even see a history of status updates for the conversion.",
        },
      ],
      newConversionTourCallbacks: {
        onStart: this.startTourCallback,
        onFinish: this.finishTourCallback,
        onSkip: this.finishTourCallback,
        onStop: this.finishTourCallback,
      },
      showTutorial: false,
    }
  },
  computed: {
    domainsList() {
      const domainsList = this.userData.domains
        ? this.userData.domains.map(x => x.domain)
        : []
      return domainsList
    },
    pageNewConversions() {
      return !!(this.defaultFilterParams && this.defaultFilterParams.status === 'new')
    },
    pageSpamConversions() {
      return !!(this.defaultFilterParams && this.defaultFilterParams.status === 'spam')
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    totalTableRows() {
      const digestData = this.$store.getters['conversions/getAllConversions']
      const linksData = digestData ? digestData.links : 0
      return linksData ? linksData.current_size * linksData.last_page : 0
    },
    userData() {
      return this.$store.getters['users/getCurrentUser']
    },
    userOnboarded() {
      let userFinishedOnboarding = false
      if (this.userData && this.userData.domains && this.userData.domains.length > 0) {
        userFinishedOnboarding = !!this.userData.domains[0].conversion_values
      }
      return userFinishedOnboarding
    },
    visibleConversions() {
      const digestData = this.$store.getters['conversions/getAllConversions']
      const tutorialData = tutorialDataArray

      let conversionData = []
      if (this.showTutorial) {
        conversionData = [...conversionData, ...tutorialData]
      } else if (digestData) {
        conversionData = [...conversionData, ...digestData.data]
      }
      return conversionData
    },
  },
  watch: {
    $attrs: {
      handler(newAttrs, oldAttrs) {
        // refresh table if viewing new type of conversions
        const newFilterParams = newAttrs && newAttrs.filterParams ? newAttrs.filterParams : { status: 'all' }
        const oldFilterParams = oldAttrs && oldAttrs.filterParams ? oldAttrs.filterParams : {}
        if (newFilterParams.status !== oldFilterParams.status) {
          // reset date range if default
          // const currentDate = new Date().toJSON().slice(0, 10)
          // this.dateRange = `${currentDate} to ${currentDate}`
          this.startDate = null
          this.endDate = null
          this.currentPage = this.$route.query.page || '1'

          // update filter params and get conversions
          this.defaultFilterParams = newAttrs.filterParams
          this.getConversions(this.defaultFilterParams)
          this.showValueColumn()
        }
      },
      immediate: true,
    },
    $route(to) {
      // refresh table if page number changes
      if (to.query.page && (to.query.page !== this.currentPage)) {
        this.currentPage = `${to.query.page}`

        const conArgs = {
          ...this.defaultFilterParams,
          page: to.query.page,
        }
        this.getConversions(conArgs)
      }
    },
    dateRange(value) {
      this.handleDateRange(value)
    },
  },
  beforeCreate() {
    if (!this.$route.query.page) {
      // set default page to 1
      this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, page: 1, size: 10 },
      })
    }
  },
  created() {
    // check if user has viewed tutorials yet
    const introModelViewed = localStorage.getItem('modal-all-conversions-intro')
    const newModelViewed = localStorage.getItem('modal-new-conversions-intro')
    if (!this.defaultFilterParams && !introModelViewed) {
      this.$bvModal.show('modal-all-conversions-intro')
      localStorage.setItem('modal-all-conversions-intro', JSON.stringify(true))
    } else if (this.pageNewConversions) {
      if (!newModelViewed) {
        setTimeout(() => {
          this.$bvModal.show('modal-new-conversions-intro')
          localStorage.setItem('modal-new-conversions-intro', JSON.stringify(true))
        }, 1000)
      }
    }
  },
  methods: {
    getConversions(args) {
      console.log('getConversions', args)
      this.loadingOverlay = true

      // set table page and size
      const conversionArgs = {
        page: this.currentPage,
        size: this.perPage,
        ...args,
      }

      // set date range
      if (this.startDate && this.endDate) {
        conversionArgs.start = this.startDate
        conversionArgs.end = this.endDate
      }

      // get conversions digest
      this.$store
        .dispatch('conversions/fetchAllConversions', conversionArgs)
        .then(response => {
          // if requested page exceeds current pages, send to actual last page
          if (
            response.data.links
            && response.data.links.current_page > response.data.links.last_page
          ) {
            this.currentPage = response.data.links.last_page
            this.$router.replace({
              name: this.$route.name,
              query: { ...this.$route.query, page: response.data.links.last_page },
            })
          }
          this.loadingOverlay = false
        })
        .catch(error => {
          this.loadingOverlay = false
          this.errorMessage = error
        })
    },
    refreshConversions() {
      console.log('refreshConversions')
      // handler for Refresh Conversions button
      this.dateRange = null
      this.startDate = null
      this.endDate = null

      this.getConversions(this.defaultFilterParams)
      this.$bvModal.show('modal-loading') // show loading message
      setTimeout(() => this.$bvModal.hide('modal-loading'), 500)
    },
    handleDateRange(dateValue) {
      // set date range
      if (dateValue) {
        const splitDate = dateValue.split(' ')
        const firstDate = splitDate[0] // earlier date in date range
        const secondDate = splitDate[2] // later date in date range
        if (splitDate.length === 1) {
          // one date only
          this.startDate = firstDate
          this.endDate = firstDate
        } else if (splitDate.length === 3) {
          // two dates with a date range
          this.startDate = firstDate
          this.endDate = secondDate
        }
        this.getConversions(this.defaultFilterParams) // refresh conversions without loading modal
      } else {
        this.startDate = null
        this.endDate = null
      }
    },
    handleResetDate() {
      // reset date range selector
      this.dateRange = ''
      this.startDate = null
      this.endDate = null
      this.getConversions(this.defaultFilterParams) // refresh conversions without loading modal
    },
    handleIgnoredForms() {
      // view ignored forms modal
      this.$refs.conversionModal.openIgnoredFormsModal()
    },
    handleDownloadSpam() {
      let downloadMessage = 'Download a CSV file with all spam conversions?'
      if (this.startDate && this.endDate) {
        if (this.startDate !== this.endDate) {
          downloadMessage = `Download a CSV file with spam conversions from ${this.startDate} to ${this.endDate}?`
        } else if (this.startDate === this.endDate) {
          downloadMessage = `Download a CSV file with spam conversions from ${this.startDate}?`
        }
      }

      // open modal to download csv
      if (this.visibleConversions && this.userData && this.domainsList.length > 0) {
        // confirm alert
        this.$swal({
          title: 'Download?',
          text: downloadMessage,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            // load csv file
            this.loadingOverlay = true
            const startDate = this.startDate || '1900-01-01'
            const endDate = this.endDate || new Date().toJSON().slice(0, 10)
            const reportArgs = {
              domains: this.domainsList,
              start: startDate,
              end: endDate,
            }
            this.$store
              .dispatch('conversions/fetchReportConversionSpam', reportArgs)
              .then(response => {
                this.loadingOverlay = false
                const responseData = response.data.data
                window.open(responseData.url, '_blank')
              })
              .catch(error => {
                this.loadingOverlay = false
                this.showErrorMessage(JSON.stringify(error))
              })
          }
        })
      } else {
        this.showErrorMessage(
          'Missing conversion data. Please reload and try again later.',
        )
      }
    },
    showValueColumn() {
      // remove star rating column in New or Rejected view
      if (
        this.defaultFilterParams
        && (this.defaultFilterParams.status === 'new'
          || this.defaultFilterParams.status === 'rejected')
      ) {
        const ratingEntry = this.fields.find(item => item.key === 'rating')
        const ratingIndex = this.fields.indexOf(ratingEntry)
        if (ratingIndex > -1) {
          this.fields.splice(ratingIndex, 1)
        }
      } else {
        const ratingEntry = this.fields.find(item => item.key === 'rating')
        const ratingIndex = this.fields.indexOf(ratingEntry)
        if (ratingIndex < 0) {
          this.fields.splice(-2, 0, {
            key: 'rating',
            label: 'Value',
            tooltip: 'The estimated conversion value',
          })
        }
      }
    },

    /* TABLE ACTIONS */

    calculateStarRating(conversionValuePennies, conversionStatus) {
      return this.$refs.conversionModal
        ? this.$refs.conversionModal.calculateStarRating(
          conversionValuePennies,
          conversionStatus,
        )
        : 0
    },
    handleItemView(conversionId) {
      // navigate to the view page for the given resource id
      this.$router.push({ name: 'view-conversion', params: { id: conversionId }, query: { returnTo: this.windowPathname } })
    },
    handleItemApprove(conversionId) {
      this.selectedModalAction = 'verify'
      if (conversionId === this.selectedConversion) {
        this.$refs.conversionModal.openApproveConversionForm()
      } else {
        this.selectedConversion = conversionId
      }
    },
    handleItemReject(conversionId) {
      // get method from conversionModal component
      this.$refs.conversionModal.rejectConversion(conversionId)
    },
    handleItemSpam(conversionId) {
      // get method from conversionModal component
      this.$refs.conversionModal.spamConversion(conversionId)
    },
    handleItemRemap(conversionId) {
      this.selectedModalAction = 'remap'
      if (conversionId === this.selectedConversion) {
        this.$refs.conversionModal.openFormRemapModal()
      } else {
        this.selectedConversion = conversionId
      }
    },
    handleItemCooldown(conversionData) {
      // open modal explaining conversion cooldown
      this.$swal({
        title: 'On Cooldown',
        html: `Conversions can't be verified with Google in the first 24 hours. Please try again later.<br/><br/>This conversion is <b>${conversionData.age_in_hours} hour(s) old</b>.`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
    },

    /* TABLE FILTER AND PAGINATION */

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.perPage = filteredItems.length
      this.currentPage = 1

      this.getConversions(this.defaultFilterParams)
    },
    handlePaginationClick(bvEvent, currentPage) {
      console.log('handlePaginationClick')
      const conArgs = {
        ...this.defaultFilterParams,
        page: currentPage,
      }
      this.getConversions(conArgs)
      this.currentPage = currentPage

      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: currentPage },
      })
      this.windowPathname = window.location.pathname + window.location.search
    },
    handlePerPage(sizeValue) {
      console.log('handlePerPage')
      const conArgs = {
        ...this.defaultFilterParams,
        size: sizeValue,
      }
      this.getConversions(conArgs)

      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, size: sizeValue },
      })
      this.windowPathname = window.location.pathname + window.location.search
    },

    /* TUTORIAL */

    newConversionsPopUp() {
      this.$bvModal.hide('modal-all-conversions-intro')
      window.location.assign('/conversions/new')
    },
    openTour() {
      this.$bvModal.hide('modal-new-conversions-intro')
      this.$tours.newConversions.start()
    },
    startTourCallback() {
      // show dummy data for conversions table
      console.log('startTourCallback')
      this.showTutorial = true
    },
    finishTourCallback() {
      // hide dummy data for conversions table
      console.log('finishTourCallback')
      this.showTutorial = false
    },

    /* MISCELLANEOUS */

    getReadableDate(dateStr) {
      // parse unix date in readable format
      const date = new Date(dateStr)
      return `${date.toLocaleDateString([], {
        dateStyle: 'long',
      })} ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },
    getReadableDateOnly(dateStr) {
      const date = new Date(dateStr)
      // Format the date object to a human-readable format
      // Return the human-readable date string
      return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },
    showErrorMessage(errorMessage) {
      this.$toast(
        {
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: errorMessage,
          },
        },
        {
          timeout: false,
        },
      )
    },
    /* Function to limit number of characters in a string. */
    limitString(string = '', limit = 0) {
      let newString = string
      if (
        typeof newString === 'object'
        && !Array.isArray(newString)
        && newString !== null
      ) {
        newString = JSON.stringify(newString)
      }
      if (newString) {
        const newStringLength = newString.length
        newString = String(newString).substring(0, limit)
        if (newStringLength > limit) {
          newString = `${newString}…`
        }
      } else {
        newString = ''
      }
      return newString
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tour.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.dropdown .btn-secondary {
  border: none;
  background-color: transparent !important;
}
.page-item {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.page-item,
.page-item.active .page-link {
  border-radius: 0.3rem;
}
.text-dark-green {
  color: #00cfe8;
}
body:not(.dark-layout) .text-secondary {
  color: #00000080 !important;
}
.actions-bar {
  min-width: 11rem;
}
body:not(.dark-layout) .v-step__content {
  color: #000000 !important;
}
body.dark-layout .v-step__content {
  color: #fff !important;
}

/* Style the caret (arrow) inside the select */
.custom-select::after {
  border-color: #7f2bda !important;
}
.flatpickr-input {
  background-color: #7f2bda !important;
  color: #fff !important;
  border: 1px solid #7f2bda !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b4b7bd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: calc(100% - 12px) 13px, calc(100% - 20px) 13px, 100% 0;
  background-size: 18px 14px, 18px 14px;
  background-repeat: no-repeat;
}
/*add a caret to the input*/
.flatpickr-input:after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #fff;
}
</style>
